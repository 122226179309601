import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../../config'

import Button from '../../../../components/Button'
import BottomBar from '../../../../components/BottomBar'

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(6),
  maxWidth: 1200,
  margin: '0 auto'
}))


const LineListingContent = () => {
  const dispatch = useDispatch()

  return (
    <>
      <Content>
        {/* Content goes here... */}
      </Content>

      <BottomBar>
        <Button
          buttonType='primary'
          raised
          aria-label={translations('Line Listing Management - Create Line Listing')}
          onClick={() => dispatch(push('/monitoring-and-analytics/line-listing/new'))}
        >
          {translations('Line Listing Management - Create Line Listing')}
        </Button>
      </BottomBar>
    </>
  )
}

export default LineListingContent
