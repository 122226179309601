import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import AnalyticsMenu from './AnalyticsMenu'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const AnalyticsScreen = () => {
  const lineListingEnabled = useSelector(platformSelectors.getHasLineListingManagementEnabled)

  const widgets = useMemo(() => {
    const widgets = [
      { id: 'analytics', title: 'Analytics', image: 'analyticsImg', icon: 'analyticsIcon' },
      { id: 'healthcheck', title: 'Health Check', image: 'caseManagementImg', icon: 'caseManagementIcon' }
    ]
    
    if (lineListingEnabled) {
      widgets.push({ id: 'line-listing', title: 'Line Listing Management', image: 'lineListingManagementImg', icon: 'lineListingManagemenIcon' })
    }
    return widgets
  }, [lineListingEnabled])

  return (
    <>
      <AnalyticsMenu widgets={widgets} isDetail={false}/>
    </>
  )
}

export default AnalyticsScreen
