import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import analyticRoutes from './organisationReporting/OrganisationReportingScreens/OrganisationReportingRoutes'
import healthCheckRoutes from './organisationHealthCheck/OrganisationHealthCheckScreens/OrganisationHealthCheckRoutes'
import AnalyticsScreen from './AnalyticsScreen'
import AnalyticsDetailScreen from './AnalyticsScreen/AnalyticsDetailMenu'
import HealthCheckView from './AnalyticsScreen/HealthCheckView'
import LineListingView from './AnalyticsScreen/LineListingView'
import NewLineListing from './AnalyticsScreen/NewLineListing'
import AnalyticsView from './AnalyticsScreen/AnalyticsView'

const widgetTypes = [
  'user',
  'report'
]

export default [
  <PrivateRoute key='/monitoring-and-analytics' path='/monitoring-and-analytics' exact component={TimedLogoutComponent(AnalyticsScreen)} />,
  <PrivateRoute key='/monitoring-and-analytics/analytics' path='/monitoring-and-analytics/analytics' exact component={TimedLogoutComponent(AnalyticsDetailScreen)} />,
  <PrivateRoute key='/monitoring-and-analytics/analytics/:widgetType' path={`/monitoring-and-analytics/analytics/:widgetType(${widgetTypes.join('|')})`} exact component={TimedLogoutComponent(AnalyticsView)} />,
  <PrivateRoute key='/monitoring-and-analytics/analytics/organisation/:widgetType' path={`/monitoring-and-analytics/analytics/organisation/:widgetType(${widgetTypes.join('|')})`} exact component={TimedLogoutComponent(AnalyticsView)} />,
  <PrivateRoute key='/monitoring-and-analytics/healthCheck' path='/monitoring-and-analytics/healthCheck' exact component={TimedLogoutComponent(HealthCheckView)} />,
  <PrivateRoute key='/monitoring-and-analytics/healthCheck' path='/monitoring-and-analytics/healthCheck/organisation' exact component={TimedLogoutComponent(HealthCheckView)} />,
  <PrivateRoute key='/monitoring-and-analytics/line-listing' path='/monitoring-and-analytics/line-listing' exact component={TimedLogoutComponent(LineListingView)} />,
  <PrivateRoute key='/monitoring-and-analytics/line-listing/new' path='/monitoring-and-analytics/line-listing/new' exact component={TimedLogoutComponent(NewLineListing)} />,
  ...analyticRoutes,
  ...healthCheckRoutes
]
