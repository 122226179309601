import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { compose } from 'recompose'
import { useDispatch, useSelector } from 'react-redux'

import { FormControl, styled } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { translations } from '../../../../../config'
import Button from '../../../../../components/Button'
import Dropdown from '../../../../../components/Fields/Dropdown'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'
import { categoryOptions } from '../../../../../store/modules/formViews/selectors'

import {
  actions as reportConfigurationActions,
  selectors as reportConfigurationSelectors
} from '../../../../../store/modules/reportConfiguration'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}))

const TextFieldWrapper = styled('div')(({ theme }) => ({
  '& div': {
    width: '100%'
  },
  '& div label': {
    position: 'static'
  }
}))

const OptionalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  alignItems: 'center'
}))


const OptionalContainerText = styled('div')(({ theme }) => ({
  textAlign: 'center', 
  color: 'rgba(0, 0, 0, 0.54)', 
  fontSize: '12px'
}))


const CreateLineListingForm = (props) => {
  const dispatch = useDispatch()
  const { typesOptions } = useSelector(platformSelectors.getCurrentPlatformReportManagementFilters)
  const [name, setName] = useState('')
  const [reportType, setReportType] = useState(null)
  const [formViews, setFormViews] = useState(null)
  const [reportConfiguration, setReportConfiguration] = useState(null)
  const [category, setCategory] = useState(null)

  const mappedTypesOptions = _.map(typesOptions, option => ({
    label: translations(option.label),
    value: option.value
  }))
  const onReportTypeClick = async (e) => {
    const value = e.target.value
    setReportType(value)
    setCategory(null)
    const formViews = await dispatch(reportConfigurationActions.fetchFormViewsByReportType({ reportType: value }))

    if (_.size(formViews) > 0) {
      const mappedFormViews = _.map(formViews, (formView) => ({
        label: `${formView.name} - ${translations(`Version - ${formView.versionId}`)}`,
        value: formView.id
      }))
      
      setFormViews(mappedFormViews)
    } else {
      setReportConfiguration(null)
      setFormViews(null)
    }
  }

  const onNameChange = (e) => {
    const value = e.target.value
    setName(value)
  }

  const onRcClick = (e) => {
    const value = e.target.value
    setReportConfiguration(value)
  }

  const onCategoryClick = (e) => {
    const value = e.target.value
    setCategory(value)
  }

  const onSelectFieldClick = () => {
    // TO DO
  }

  return (
    <Container >
      <Grid container spacing={5} alignItems='center'>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <TextFieldWrapper>
              <TextField
                label={translations('Line Listing Management - Create Line Listing - Name')}
                placeholder={translations('Line Listing Management - Create Line Listing - Name Placeholder')}
                disabled={false}
                InputLabelProps={{
                  shrink: true,
                  required: true
                }}
                onChange={onNameChange}
                value={name}
                inputProps={{ maxLength: 100 }}
              />
            </TextFieldWrapper>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <Dropdown
              label={translations('Line Listing Management - Create Line Listing - Report Type')}
              value={reportType}
              options={mappedTypesOptions}
              onChange={onReportTypeClick}
              noErrorTextLabel
              shrink
              required
            />
          </FormControl>
        </Grid>


        <Grid item xs={12} md={5}>
          <OptionalContainer>
            <FormControl fullWidth>
              <Dropdown
                label={translations('Line Listing Management - Create Line Listing - Report Configuration')}
                value={reportConfiguration}
                options={formViews}
                onChange={onRcClick}
                noErrorTextLabel
                shrink
                disabled={_.size(formViews) === 0 || category}
              />
            </FormControl>
            
            <OptionalContainerText>
              {translations('or')}
            </OptionalContainerText>

            <FormControl fullWidth>
              <Dropdown
                label={translations('Line Listing Management - Create Line Listing - Category')}
                value={category}
                options={categoryOptions}
                onChange={onCategoryClick}
                noErrorTextLabel
                shrink
                disabled={!reportType || reportConfiguration}
              />
            </FormControl>
          </OptionalContainer>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button 
            buttonType={'primary'} 
            onClick={onSelectFieldClick} 
            aria-label={translations('Line Listing Management - Create Line Listing - Select Fields')}
            disabled={!name || !reportType}
          >
            {translations('Line Listing Management - Create Line Listing - Select Fields')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CreateLineListingForm
