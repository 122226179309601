import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { translations } from '../../../config'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as formViewSelectors, actions as formViewActions } from '../../../store/modules/formViews'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import ToolTip from '@material-ui/core/Tooltip'
import { useSelector } from 'react-redux'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Editable from '../../../components/Editable'
import IconText from '../../../components/IconText'
import Warning from '@material-ui/icons/Warning'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import modalService from '../../../services/modalService'
import Button from '../../../components/Button'
import FileBrowserEnhancer from '../../../components/FileBrowserEnhancer'
import getFormSchemaLabel from '../../../helpers/getFormSchemaLabel'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { withStyles } from '@material-ui/core/styles'
import { useExistingReportErrors } from '../hooks'
import style from './style'


const ReportScreenBase = Editable(({ mode, classes, match, ReportFormRender, fetchReport, onLoadExisting, formState, downloadReport, updateButton}) => {
  const dispatch = useDispatch()
  
  const submissionReportId = _.get(match, 'params.submissionReportId')
  const followUpNotificationId = _.get(match, 'params.notificationId')

  const reportDetailsFormSchemaName = useSelector(reportDetailsSelectors.getFormSchemaName)
  const schemaName = useSelector(formViewSelectors.getSelectedSchemaName) || reportDetailsFormSchemaName
  const formSchemaName = useSelector(formViewSelectors.getSelectedFormSchemaName) || schemaName

  const selectedOrganisation = useSelector(authSelectors.getSelectedOrganisation)

  const showUpdates = useSelector(authCombinedSelectors.userCanSeeReportUpdates)
  const hasNewerVersion = useSelector(reportDetailsSelectors.getHasNewerVersion)
  const showVersionWarning = hasNewerVersion && showUpdates

  const title = _getTitle({ formTitle: schemaName, isNewFormMode: true })
  const pageTitle = useSelector(platformSelectors.getPageTitle)(title)
  const existingReport = useSelector(reportDetailsSelectors.getExistingReport)

  const [isLoading, setIsLoading] = useState(false)
  useExistingReportErrors(existingReport)
   
  const _renderWarning = ({ classes }) => {
    return (
      <div className={classes.warningContainer}>
        <IconText iconSize={26} fontSize={16} icon={Warning} text={translations('A newer version of this report is available')} />
      </div>
    )
  }
  useEffect(() => {
    if (submissionReportId && selectedOrganisation && fetchReport) {
      fetchReport()
        .then(() => setIsLoading(false))  // Success
        .catch(() => setIsLoading(false))  // Failure
    }
    
    return () => {
      dispatch(formViewActions.selectReport(null))
    }
  }, [submissionReportId, selectedOrganisation, fetchReport])
 

  const loadableReports = _.includes([
    'devicesReport',
    'mhraR2Report',
    'mhraR3Report',
    'fscaReport'
  ], formSchemaName)


  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={() => onBackClick({ dispatch, isDirty: _.get(formState, 'dirty'), schemaName, mode })} />
        )}
        centerContent={(
          <>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </>
        )}
        rightContent={
          <>
            {mode === 'edit' && loadableReports &&
              <FileBrowserEnhancer
                accept={['.xml']}
                onChange={onLoadExisting}
                renderContent={({ onClick }) => (
                  <Button onClick={onClick} raised variant='contained' style={{ marginRight: 20 }}>
                    {translations('Load Existing Report')}
                  </Button>
                )}
              />
            }
            {updateButton}
            {mode === 'review-anonymised' && (
              <ToolTip title={translations('Anonymised Report Review - Download Anonymised Report')}>
                <Button
                  onClick={downloadReport}
                  variant='contained'
                  buttonType='primary'
                  style={{ marginRight: 20 }}
                  aria-label={translations('Anonymised Report Review - Download Anonymised Report')}
                >
                 <DownloadIcon />
               </Button>
              </ToolTip>
            )}
          </>
        }
      />
      {showVersionWarning && !followUpNotificationId && _renderWarning({ classes })}
      {!isLoading && ReportFormRender}
    </div>
  )
})


const _getTitle = ({ editing, isNewFormMode, reportStatus, formTitle }) => {
  const formType = reportStatus === 'draft' ? 'Draft' : 'Report'
  const isEditing = !!editing ? 'Edit' : 'View'
  if (isNewFormMode) {
    return getFormSchemaLabel(formTitle) || `Standard`
  }
  return translations(`${isEditing} ${formType}`)
}

const onBackClick = ({ dispatch, isDirty, schemaName, mode }) => {
  if (!isDirty) {
    dispatch(goBack())
    return
  }

  let warningMessage
  switch (schemaName) {
    case 'devicesReport':
      warningMessage = 'Discard Devices Report Notice Text'
      break
    case 'sabreSAR':
    case 'sabreSAE':
      warningMessage = 'Discard SAE/SAR Notice Text'
      break
    case 'mhraR2Report':
    case 'mhraR3Report':
      warningMessage = 'Discard Report Notice Text'
      break
    default:
      warningMessage = 'Discard Report Notice Text Default'
  }
  if(!['review','review-anonymised'].includes(mode)){
    modalService.action({
      title: translations('Warning'),
      text: translations(warningMessage),
      actions: [
        { text: translations('Cancel') },
        {
          text: translations('Discard changes and go back'),
          onClick: () => {
            dispatch(goBack())
          }
        }
      ]
    })
  }
  else{
    dispatch(goBack())
  }
}
export default withStyles(style)(ReportScreenBase)
