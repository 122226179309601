import React from 'react'
import LineListingHeader from '../LineListingHeader'
import { translations } from '../../../../config'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'

import { styled } from '@material-ui/core/styles'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import Heading from '../../../../components/Heading'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import Button from '../../../../components/Button'

import CreateLineListingForm from './CreateLineListingForm'


const Content = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10
})

const GenerateButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
}))

const NewLineListing = () => {
  
  return (
    <>
      <LineListingHeader headerText={translations('Line Listing Management - Create Line Listing')}/>
      <CreateLineListingForm />
    </>
  )
}

export default NewLineListing
