import React from 'react'
import LineListingContent from '../LineListingContent'
import LineListingHeader from '../LineListingHeader'
import { translations } from '../../../../config'

const LineListingView = () => {
  return (
    <>
      <LineListingHeader headerText={translations('App Name - Line Listing Management')} />
      <LineListingContent />
    </>
  )
}

export default LineListingView
